import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/organisms/Layout"
import { Hero } from "../components/organisms/Hero"
import { Callout } from "../components/organisms/Callout"
import { CTA } from "../components/organisms/CTA"
import { OurClients } from "../components/organisms/OurClients"
import { ImageLinkBlocks } from "../components/organisms/ImageLinkBlocks"
import { FeaturedNews } from "../components/organisms/FeaturedNews"
import { ProductList } from "../components/organisms/ProductList"
import { RichText } from "../components/organisms/RichText"
import { ContactForm } from "../components/organisms/ContactForm"
import { Region } from "../components/organisms/Region"
import { SEO } from "../components/atoms/SEO"
import { FullImageSection } from "../components/organisms/FullImageSection"
import { ResponsiveGraphicSection } from "../components/organisms/ResponsiveGraphicSection"

import { AllNews } from "../components/organisms/AllNews"
import { RNTI } from "../components/organisms/RNTI"
import { CategorizedRichTextCards } from "../components/organisms/CategorizedRichTextCards"
import { TwoColumnText } from "../components/organisms/TwoColumnText"
import { RichTextCardList } from "../components/organisms/RichTextCardList"
import { Mosaic } from "../components/organisms/Mosaic"
import { Animation } from "../components/organisms/Animation"
import { SEO as SEOType } from "../types"
import { RichTextCallout } from "../components/organisms/RichTextCallout"
import { ProductFeatureTiles } from "../components/organisms/ProductFeatureTiles"
import { Carousel } from "../components/organisms/Carousel"
import { EmployeeCarousel } from "../components/organisms/EmployeeCarousel"
import { TeamSlider } from "../components/organisms/TeamSlider"
import { ClientSlider } from "../components/organisms/ClientSlider"

import { ProductSpotlight } from "../components/organisms/ProductSpotlight"
import { FeaturesList } from "../components/organisms/FeaturesList"
import { SpotlightUnify } from "../components/organisms/SpotlightUnify"
import { SpotlightLSTI } from "../components/organisms/SpotlightLSTI"
import { SpotlightDirectuspro } from "../components/organisms/SpotlightDirectuspro"
import { SocialMediaEmbeds } from "../components/organisms/SocialMediaEmbeds"
import { ILACForm } from "../components/organisms/ILACForm"
import { Values } from "../components/organisms/Values"
import { Team } from "../assets/icons/TitleIcons"
import { Culture } from "../components/organisms/Culture"
import Page, { innerQuery } from "./page"

type PageTemplateProps = {
  data: {
    page: {
      title: string
      category?: { category?: string }
      slug: {
        current: string
      }
      sections: Object[]
      seo: SEOType
      seoImage?: {
        asset?: {
          url?: string
        }
      }
    }
  }
}

;<Page />

const RntiPage = Page

export default RntiPage

export const query = graphql`
  query Page($id: String!) {
    page: sanityRntiPage(id: { eq: $id }) {
      title
      category {
        category
      }
      slug {
        current
      }
      seo {
        _type
        focus_keyword
        meta_description
        seo_title
      }
      seoImage {
        asset {
          url
        }
      }
      sections {
        ...HeroData
        ...CalloutData
        ...CtaData
        ...FeaturedClientsData
        ...ImageLinkBlockData
        ...FeaturedNewsData
        ...ProductListData
        ...RichTextData
        ...FormData
        ...RegionData
        ...FullImageData
        ...ResponsiveGraphicData
        ...AllNewsData
        ...RntiData
        ...ValuesData
        ...CultureData
        ...MosaicData
        ...CategorizedRichTextData
        ...TwoColumnTextData
        ...RichTextCardsData
        ...AnimationData
        ...RichTextCalloutData
        ...ProductFeatureTilesData
        ...CarouselData
        ...EmployeeCarouselData
        ...TeamSliderData
        ...ClientSliderData
        ...ProductSpotlightData
        ...FeatureListData
        ...SpotlightUnifyData
        ...SpotlightLstiData
        ...SpotlightDirectusproData
        ...SocialMediaEmbedData
        ...IlacFormData
      }
    }
  }
`
